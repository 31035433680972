import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { CssBaseline, IconButton, Stack, createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { useEffect, useState } from 'react';

const darkTheme = createTheme({
    typography: {
        fontFamily: 'Open Sans Hebrew, sans-serif',
    },
    palette: {
        mode: 'dark',
        background: {
            default: "#17202C"
        }
    },
});


export const ContactForm = () => {

    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        formData.append('_wpcf7_unit_tag', 'wpcf7-f3671-p3671-o1');
        formData.append('_wpcf7', '3671');
        console.log(formData);
        fetch('/wp-json/contact-form-7/v1/contact-forms/3671/feedback', {
            'method': 'POST',
            'body': formData
        }).then(response => {
            response.json().then(data => {
                console.log(data);
                setMessage(data.message);
                setTimeout(() => {
                    setMessage('');
                }, 50000)
            })
        });
        // Handle form submission logic here
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />

            <Stack spacing={2} direction="column" sx={{ p: 2 }}>


                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Name"
                        variant="standard"
                        name="your-name"
                        required
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        variant="standard"
                        name="your-email"
                        required
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Phone"
                        variant="standard"
                        name="your-phone"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Message (optional)"
                        variant="standard"
                        name="your-message"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                    />
                    <Button type="submit" variant="text" color="primary">
                        + Submit
                    </Button>
                </form>
                {message && <p style={{color:'white!important'}}>{message}</p>}
            </Stack>

        </ThemeProvider>
    );
};
